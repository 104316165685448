// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-jsx": () => import("./../../../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-order-online-jsx": () => import("./../../../src/pages/order-online.jsx" /* webpackChunkName: "component---src-pages-order-online-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */)
}

